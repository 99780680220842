import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Text,
  VStack,
  Flex,
  Icon,
  Button,
} from '@chakra-ui/react';
import { FaTimesCircle } from 'react-icons/fa';

interface CanceledCollabModalProps {
  isOpen: boolean;
  onClose: () => void;
  clientName: string;
}

const CanceledCollabModal: React.FC<CanceledCollabModalProps> = ({ isOpen, onClose, clientName }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size="lg">
      <ModalOverlay />
      <ModalContent
        bg="gray.50"
        borderRadius="xl"
        boxShadow="xl"
        p={6}
      >
        <ModalHeader textAlign="center" fontSize="2xl" fontWeight="bold" color="red.500">
          Collaboration Rejected
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing={6} align="stretch">
            <Flex justify="center">
              <Icon as={FaTimesCircle} w={16} h={16} color="red.500" />
            </Flex>
            <Text textAlign="center" fontSize="lg">
              You rejected the collab for {clientName}. This opportunity is no longer available. Please tap the help button if you have any questions.
            </Text>
            <Button
              bg="black"
              color="white"
              _hover={{ bg: "gray.800" }}
              onClick={onClose}
            >
              Close
            </Button>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default CanceledCollabModal;