import React, { useContext, useState } from 'react';
import { Box, VStack, Text, Heading, Flex, Button } from '@chakra-ui/react';
import { CollabDataContext } from '../../contexts/collabDataContext';
import { InfluencerCampaignDataContext } from '../../contexts/InfluencerCampaignDataContext';
import PastCollabsList from './components/PastCollabsList';
import { useToast } from '@chakra-ui/react';
import { processCollabPayment } from '../../services/flaskService';
import { claimCollabPayment } from 'services/firebaseService';
import { InfluencerDataContext } from '../../contexts/InfluencerDataContext';
import ConfirmPhoneModal from './components/ConfirmPhoneModal';

const WalletPage: React.FC = () => {
  const collabData = useContext(CollabDataContext);
  const campaignData = useContext(InfluencerCampaignDataContext);
  const toast = useToast();
  const { influencerData } = useContext(InfluencerDataContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);

  // Calculate total balance from completed collabs that haven't been claimed
  const totalBalance = collabData
    .filter(collab => 
      ['collabCompleted'].includes(collab.status) && 
      collab.cash > 0 && 
      !collab.paymentClaimed
    )
    .reduce((total, collab) => total + (collab.cash || 0), 0);

  const handleWithdrawClick = () => {
    setIsModalOpen(true);
  };

  const handleConfirmWithdraw = async (confirmedCountryCode: string, confirmedPhoneNumber: string) => {
    setIsProcessing(true);
    try {
      const unclaimedCollabs = collabData.filter(collab => 
        ['collabCompleted'].includes(collab.status) && 
        collab.cash > 0 && 
        !collab.paymentClaimed
      );

      const totalAmount = unclaimedCollabs.reduce((total, collab) => total + collab.cash, 0);

      //Process single payment through Flask backend
      const success = await processCollabPayment(
        unclaimedCollabs.map(collab => collab.id).join(','),
        totalAmount,
        confirmedPhoneNumber,
        confirmedCountryCode,
        influencerData.instagramHandle
      );

      if (success) {
        await Promise.all(
          unclaimedCollabs.map(collab => claimCollabPayment(collab.id))
        );

        toast({
          title: "Withdrawal Initiated",
          description: "You should receive a message shortly with your payment details",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        setIsModalOpen(false);
      } else {
        throw new Error("Payment failed to process");
      }
    } catch (error) {
      console.error('Error processing withdrawal:', error);
      toast({
        title: "Error",
        description: "There was an error processing your withdrawal",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <Box minH="100vh" bg="white" p={4}>
      <VStack spacing={6} align="stretch">
        <Heading size="lg">My Wallet</Heading>
        <Box borderWidth={1} borderRadius="lg" p={4}>
          <Text fontSize="xl" fontWeight="bold" mb={2}>Available Balance</Text>
          <Flex justify="space-between" align="center">
            <Text fontSize="3xl">${totalBalance.toFixed(2)}</Text>
            <Button 
              bg="black" 
              color="white" 
              size="md"
              onClick={handleWithdrawClick}
              isDisabled={totalBalance <= 0}
            >
              Withdraw All
            </Button>
          </Flex>
        </Box>
        <PastCollabsList />
      </VStack>

      <ConfirmPhoneModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onConfirm={handleConfirmWithdraw}
        isProcessing={isProcessing}
      />
    </Box>
  );
};

export default WalletPage;
