import React, { useState, useEffect, useContext } from 'react';
import { Box, Text, VStack, IconButton, Collapse, Button, Flex, useDisclosure } from '@chakra-ui/react';
import { ArrowBackIcon } from '@chakra-ui/icons';
import { useNavigate, useLocation } from 'react-router-dom';
import deliverableNames from '../../../utils/deliverableNames';
import { CollabDataContext } from 'contexts/collabDataContext';
import { InfluencerCampaignDataContext } from 'contexts/InfluencerCampaignDataContext';
import InputLinkModal from '../components/InputLinkModal';
import CreativeBriefModal from '../components/CreativeBriefModal';
import PendingReviewModal from '../components/PendingReviewModal';
import CompletedDeliverableModal from '../components/CompletedDeliverableModal';

const CompleteCollabPage: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const collabId = queryParams.get('id');
  const [deliverableLinks, setDeliverableLinks] = useState<{ [key: string]: any[] }>({});
  const [currentDeliverable, setCurrentDeliverable] = useState<{ key: string, index: number } | null>(null);
  const [showFeedback, setShowFeedback] = useState(false);
  const { isOpen: isCreativeBriefOpen, onOpen: onCreativeBriefOpen, onClose: onCreativeBriefClose } = useDisclosure();
  const [isPendingModalOpen, setIsPendingModalOpen] = useState(false);
  const [currentPendingLink, setCurrentPendingLink] = useState('');
  const [isCompletedModalOpen, setIsCompletedModalOpen] = useState(false);
  const [completedDeliverableName, setCompletedDeliverableName] = useState('');

  const collabData = useContext(CollabDataContext);
  const campaignData = useContext(InfluencerCampaignDataContext);
  const collab = collabData.find(c => c.id === collabId);
  const campaign = campaignData[collabId!];

  useEffect(() => {
    if (collab && collab.deliverableLinks) {
      setDeliverableLinks(collab.deliverableLinks);
    }
  }, [collab]);

  const sortedDeliverables = Object.entries(deliverableLinks)
    .filter(([_, links]) => links.length > 0)
    .sort(([keyA], [keyB]) => keyA.localeCompare(keyB));

  const handlePostButtonClick = (key: string, index: number, showFeedback: boolean = false) => {
    if (deliverableLinks[key][index].status === 'pendingVerification') {
      setCurrentPendingLink(deliverableLinks[key][index].userLink);
      setIsPendingModalOpen(true);
    } else if (deliverableLinks[key][index].status === 'approved') {
      setCompletedDeliverableName(deliverableNames[key as keyof typeof deliverableNames]?.singular);
      setIsCompletedModalOpen(true);
    } else {
      setCurrentDeliverable({ key, index });
      setShowFeedback(showFeedback);
    }
  };

  const handleCloseModal = () => {
    setCurrentDeliverable(null);
    setShowFeedback(false);
  };

  const includeInstagramHandle = () => {
    return campaign?.instagramHandle && 
    (deliverableLinks.instagramPosts?.length > 0
      || deliverableLinks.instagramReels?.length > 0 
      || deliverableLinks.instagramStories?.length > 0);
  }

  const includeTiktokHandle = () => {
    return campaign?.tiktokHandle && deliverableLinks.tiktoks?.length > 0;
  }

  const isHomeDelivery = () => {
    return campaign?.campaignType === 'shipToHome';
  };

  if (!collab) {
    return <div>Loading...</div>;
  }

  return (
    <Box p={5} minHeight="100vh" display="flex" flexDirection="column" alignItems="center">
      <IconButton
        icon={<ArrowBackIcon />}
        aria-label="Back"
        onClick={() => navigate(-1)}
        mb={4}
        variant="ghost"
        alignSelf="flex-start"
      />
      <VStack spacing={4} align="stretch" flex={1} width="100%">
        <Text fontSize="3xl" fontWeight="bold">Time to Post!</Text>
        <CreativeBriefModal
          isOpen={isCreativeBriefOpen}
          onClose={onCreativeBriefClose}
          collab={collab}
          campaign={campaign}
        />
        <Box borderWidth={1} borderRadius="md" p={3}>
          <Text fontSize="xl" fontWeight="bold">Instructions</Text>
          <Text fontSize="lg">
            Please post the following deliverables and we will verify them shortly.<br />
            After posting each deliverable, click "I posted" and enter the link to your post.<br />
            {includeInstagramHandle() && (
              <>
                Tag and Add @{campaign.instagramHandle} as a collaborator on Instagram<br />
              </>
            )}
            {includeTiktokHandle() && `Tag and Add @${campaign.tiktokHandle} as a collaborator on TikTok`}
          </Text>
        </Box>
        {isHomeDelivery() && (
          <Box borderWidth={1} borderRadius="md" p={3} bg="gray.50">
            <Text fontSize="xl" fontWeight="bold" mb={2}>Need Help?</Text>
            <Text fontSize="lg">
              Have questions about the collab or need an update on shipping?
            </Text>
            <Button
              variant="link"
              color="blue.500"
              onClick={() => 
                window.Intercom('show')
              }
            >
              Get help here
            </Button>
          </Box>
        )}
        <Box borderWidth={1} borderRadius="md" p={6}>
          {sortedDeliverables.map(([key, links]) => {
            return (
              <Box key={key} mb={4}>
                {links.map((link, index) => {
                  let buttonText = '';
                  let buttonColorScheme = '';
                  let buttonTextColor = '';
                  let isButtonDisabled = false;
                  let buttonBorderColor = null;
                  let onClickHandler = () => {};

                  if (link.status === 'pendingSubmission' && !link?.rejectionReason) {
                    buttonText = 'I posted';
                    buttonColorScheme = 'white';
                    buttonTextColor = 'black';
                    buttonBorderColor = 'black';
                    onClickHandler = () => handlePostButtonClick(key, index);
                  } else if (link.status === 'pendingVerification') {
                    buttonText = 'Pending';
                    buttonColorScheme = 'gray.200';
                    buttonTextColor = 'black';
                    onClickHandler = () => handlePostButtonClick(key, index);
                  } else if (link.status === 'pendingSubmission' && link?.rejectionReason) {
                    buttonText = 'Resubmit';
                    buttonColorScheme = 'red.500';
                    buttonTextColor = 'white';
                    onClickHandler = () => handlePostButtonClick(key, index, true);
                  } else if (link.status === 'approved') {
                    buttonText = 'Completed';
                    buttonColorScheme = 'green.500';
                    buttonTextColor = 'white';
                    onClickHandler = () => handlePostButtonClick(key, index);
                  }

                  const modalHeader = `${deliverableNames[key as keyof typeof deliverableNames]?.singular} #${index + 1}`;

                  return (
                    <Flex key={index} alignItems="center" justifyContent="space-between" mb={4}>
                      <Text fontSize="lg">
                        {modalHeader}
                      </Text>
                      <Button
                        size="md"
                        bg={buttonColorScheme}
                        color={buttonTextColor}
                        borderColor={buttonBorderColor}
                        borderWidth={buttonBorderColor ? 1 : 0}
                        onClick={onClickHandler}
                        isDisabled={isButtonDisabled}
                        width="120px"
                      >
                        {buttonText}
                      </Button>
                      <InputLinkModal
                        isOpen={currentDeliverable?.key === key && currentDeliverable?.index === index}
                        onClose={handleCloseModal}
                        deliverableKey={currentDeliverable?.key}
                        postLink={link.userLink}
                        deliverableLinks={deliverableLinks}
                        linkIndex={index}
                        showFeedback={showFeedback}
                        rejectionReason={link.rejectionReason}
                        modalHeader={modalHeader}
                        clientName={campaign?.clientName}
                      />
                    </Flex>
                  );
                })}
              </Box>
            );
          })}
        </Box>
        <Box flex={1} />
        <Button 
          onClick={onCreativeBriefOpen} 
          bg="white" 
          color="black" 
          variant="solid" 
          borderColor="black"
          borderWidth={1}
          size="lg" 
          _hover={{ bg: "blue.600", transform: "scale(1.05)" }}
          _active={{ bg: "blue.700", transform: "scale(0.95)" }}
          transition="all 0.2s"
        >
          View Collab Details
        </Button>
        <PendingReviewModal
          isOpen={isPendingModalOpen}
          onClose={() => setIsPendingModalOpen(false)}
          postLink={currentPendingLink}
        />
        <CompletedDeliverableModal
          isOpen={isCompletedModalOpen}
          onClose={() => setIsCompletedModalOpen(false)}
          deliverableName={completedDeliverableName}
        />
      </VStack>
    </Box>
  );
};

export default CompleteCollabPage;
