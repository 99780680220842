import React from 'react';
import { Box } from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';
import TabBar from './TabBar';

interface MainLayoutProps {
  children: React.ReactNode;
}

const MainLayout: React.FC<MainLayoutProps> = ({ children }) => {
  const location = useLocation();
  const showTabBar = ['/collabs', '/wallet'].includes(location.pathname);

  return (
    <Box minH="100vh" bg="white">
      <Box pb={showTabBar ? "60px" : "0"}>
        {children}
      </Box>
      {showTabBar && <TabBar />}
    </Box>
  );
};

export default MainLayout;
