import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Text,
  VStack,
  Icon,
} from '@chakra-ui/react';
import { FaCheckCircle } from 'react-icons/fa';

interface CompletedDeliverableModalProps {
  isOpen: boolean;
  onClose: () => void;
  deliverableName: string;
}

const CompletedDeliverableModal: React.FC<CompletedDeliverableModalProps> = ({ isOpen, onClose, deliverableName }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent mx={4}>
        <ModalHeader textAlign="center">Deliverable Completed!</ModalHeader>
        <ModalBody>
          <VStack spacing={4}>
            <Icon as={FaCheckCircle} w={12} h={12} color="green.500" />
            <Text textAlign="center">
              Great job! Your {deliverableName} has been completed and verified.
            </Text>
            <Text textAlign="center" fontSize="sm" color="gray.600">
              Keep up the good work and continue with your other deliverables.
            </Text>
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button bg="black" color="white" mr={3} onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default CompletedDeliverableModal;

