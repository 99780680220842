import React, { useState, useEffect } from 'react';
import { Flex, Box, Text, Icon } from '@chakra-ui/react';
import { useNavigate, useLocation } from 'react-router-dom';
import { MdHome, MdAccountBalanceWallet, MdLiveHelp } from 'react-icons/md';

const TabBar: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isIntercomOpen, setIsIntercomOpen] = useState(false);

  const toggleIntercom = () => {
    const newState = !isIntercomOpen;
    setIsIntercomOpen(newState);
    window.Intercom(newState ? 'show' : 'hide');
  };

  useEffect(() => {
    const handleIntercomClose = () => {
      setIsIntercomOpen(false);
    };

    window.Intercom('onHide', handleIntercomClose);

    return () => {
      window.Intercom('offHide', handleIntercomClose);
    };
  }, []);

  const tabs = [
    { icon: MdHome, label: 'Home', path: '/collabs' },
    { icon: MdAccountBalanceWallet, label: 'Wallet', path: '/wallet' },
    { icon: MdLiveHelp, label: 'Help', path: '/help', onClick: toggleIntercom }
  ];

  return (
    <Flex
      position="fixed"
      bottom={0}
      left={0}
      right={0}
      bg="white"
      borderTop="1px solid"
      borderColor="gray.200"
      height="60px"
      justify="space-around"
      align="center"
      zIndex={1000}
    >
      {tabs.map((tab) => (
        <Box
          key={tab.path}
          flex={1}
          textAlign="center"
          cursor="pointer"
          onClick={tab.onClick ? tab.onClick : () => navigate(tab.path)}
          py={2}
        >
          <Icon
            as={tab.icon}
            w={6}
            h={6}
            color={location.pathname === tab.path ? 'black' : 'gray.500'}
            mb={1}
          />
          <Text
            fontSize="xs"
            color={location.pathname === tab.path ? 'black' : 'gray.500'}
          >
            {tab.label}
          </Text>
        </Box>
      ))}
    </Flex>
  );
};

export default TabBar;
