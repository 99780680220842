const deliverableNames = {
    tiktoks: {
      singular: "TikTok",
      plural: "TikToks",
      short: "TikTok"
    },
    instagramPosts: {
      singular: "Instagram Post",
      plural: "Instagram Posts",
      short: "IG Post"
    },
    instagramReels: {
      singular: "Instagram Reel",
      plural: "Instagram Reels",
      short: "IG Reel"
    },
    instagramStories: {
      singular: "Instagram Story",
      plural: "Instagram Stories",
      short: "IG Story"
    }
  };
  
  export default deliverableNames;