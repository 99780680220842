import React, { useEffect } from 'react';
import { RecaptchaVerifier } from 'firebase/auth';
import { auth } from '../../../firebase';  // Ensure this is correctly pointing to your Firebase auth instance

declare global {
  interface Window {
    grecaptcha: any;
    recaptchaVerifier: RecaptchaVerifier | undefined;
  }
}

const RecaptchaInitializer: React.FC = () => {
  useEffect(() => {
    const recaptchaContainerId = 'recaptcha-container';

    const loadRecaptcha = () => {
      const existingScript = document.querySelector('script[src="https://www.google.com/recaptcha/api.js"]');
      if (existingScript) {
        document.head.removeChild(existingScript);
      }

      const script = document.createElement('script');
      script.src = 'https://www.google.com/recaptcha/api.js';
      script.async = true;
      script.defer = true;
      script.onload = () => {
        if (document.getElementById(recaptchaContainerId) && !window.recaptchaVerifier) {
          window.recaptchaVerifier = new RecaptchaVerifier(auth, recaptchaContainerId, {
            size: 'invisible', // 'normal' or 'compact'
          //  badge: 'middle'    // 'bottomright', 'bottomleft' or 'inline'
          });
          window.recaptchaVerifier.render().catch(e => {
            console.error('Error rendering reCAPTCHA:', e);
          });
        }
      };
      script.onerror = () => {
        console.error('Failed to load reCAPTCHA script');
      };
      document.head.appendChild(script);
    };

    if (!window.grecaptcha || !window.recaptchaVerifier) {
      loadRecaptcha();
    }

    return () => {
      if (window.recaptchaVerifier) {
        window.recaptchaVerifier.clear(); // Clear the verifier on unmount
        window.recaptchaVerifier = undefined;
      }
      const script = document.querySelector('script[src="https://www.google.com/recaptcha/api.js"]');
      if (script) {
        document.head.removeChild(script);
      }
    };
  }, []);

  return <div id="recaptcha-container" style={{ display: 'none' }}></div>;
};

export default RecaptchaInitializer;
