import React from 'react';
import { Box, Text, VStack, Image, Button, Link } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';

const EmailSentPage: React.FC = () => {
  const navigate = useNavigate();

  return (
    <Box
      minH="100vh"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      bgGradient="linear(to-t, #939393, white 50%, white)"
      p="4"
    >
      <Box position="absolute" top="5vh">
        <Image 
          src="https://firebasestorage.googleapis.com/v0/b/storyitapp.appspot.com/o/touchpoints%2FFoodFluence.io%20Logo%20(461%20x%20196%20px)%20(6).png?alt=media&token=54ec9ac5-6bd9-40e1-98c4-12597c3953a1" 
          alt="FoodFluencer Logo" 
          maxWidth="100%"
          objectFit="contain"
        />
      </Box>
      <motion.div
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', flex: 1 }}
      >
        <VStack spacing={8} align="center" width="100%" maxWidth="100vw">
          <motion.div
            initial={{ scale: 0.9, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            transition={{ duration: 0.5, delay: 0.2 }}
          >
            <Box 
              borderWidth={1}
              borderRadius="2xl"
              overflow="hidden"
              boxShadow="2xl"
              bg="white"
              p={8}
              textAlign="center"
              maxWidth="90vw"
              position="relative"
              _before={{
                content: '""',
                position: 'absolute',
                top: '-10px',
                left: '-10px',
                right: '-10px',
                bottom: '-10px',
                background: 'linear-gradient(45deg, #FF6B6B, #4ECDC4)',
                filter: 'blur(20px)',
                opacity: 0.3,
                zIndex: -1,
              }}
            >
              <motion.div
                initial={{ y: 20, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ duration: 0.5, delay: 0.4 }}
              >
                <Text fontSize="3xl" fontWeight="bold" color="black" mb={4}>
                  We've sent you a link!
                </Text>
                <Text fontSize="lg" color="gray.600" mb={6}>
                  Please check your email to continue sign in.
                  Don't forget to check your spam folder if you don't see it.
                </Text>
                <Text fontSize="sm" color="gray.500" mt={8} textDecoration="underline">
                  <Link color="black" onClick={() => navigate('/login')}>Go Back to Login</Link>
                </Text>
              </motion.div>
            </Box>
          </motion.div>
        </VStack>
      </motion.div>
    </Box>
  );
};

export default EmailSentPage;