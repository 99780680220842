import React, { useEffect, useState, useContext } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { Box, Button, Input, FormControl, VStack, Text, useToast, Select, Flex } from '@chakra-ui/react';
import { sendPhoneVerificationCode, checkInfluencerPhone } from '../../services/firebaseService';
import { VerificationContext } from '../../contexts/PhoneVerificationContext';
import { RecaptchaVerifier } from 'firebase/auth';
import { countryOptions } from 'types';

declare global {
  interface Window {
    grecaptcha: any;
    recaptchaVerifier: RecaptchaVerifier;
  }
}

const ReturningUser: React.FC = () => {
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [countryCode, setCountryCode] = useState<string>('+1'); // Default country code
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [recaptchaLoaded, setRecaptchaLoaded] = useState<boolean>(false);
  const navigate = useNavigate();
  const toast = useToast();
  const { setConfirmationResult } = useContext(VerificationContext);

  useEffect(() => {
    const recaptchaContainer = document.getElementById('recaptcha-container');
    if (recaptchaContainer) {
      recaptchaContainer.style.visibility = 'hidden';
      setRecaptchaLoaded(true);
    }

    return () => {
      if (recaptchaContainer) {
        recaptchaContainer.style.visibility = 'hidden';
      }
    };
  }, []);

  const handleSendCode = async () => {
    if (!recaptchaLoaded || !window.recaptchaVerifier) {
      toast({
        title: "Error",
        description: "reCAPTCHA not initialized. Please try again later.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    setIsLoading(true);
    try {
      const fullPhoneNumber = `${countryCode}${phoneNumber}`;
      const isValid = await checkInfluencerPhone(fullPhoneNumber);
      if (!isValid) {
        toast({
          title: "Error",
          description: "We do not have an account for this phone number.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      } else {
        toast({
          title: "Success",
          description: "Phone number recognized. Sending code to: " + fullPhoneNumber,
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        const result = await sendPhoneVerificationCode(fullPhoneNumber);
        setConfirmationResult(result);
        navigate('/verification');
      }
    } catch (error) {
      console.error('Error sending verification code:', error);
      toast({
        title: "Error",
        description: "An error occurred while sending the verification code. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box
      minH="100vh"
      display="flex"
      flexDirection="column"
      bg="white"
      p={{ base: "4", md: "6", lg: "8" }}
      position="relative"
    >
      <Button 
        onClick={() => navigate('/login')} 
        position="absolute"
        top="16px"
        left="16px"
        variant="ghost"
      >
        &larr;
      </Button>

      <VStack 
        spacing={6} 
        align="center" 
        width="100%" 
        maxWidth="400px"
        mx="auto"
        flex="1"
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        py="8"
      >
        {/* Top section */}
        <VStack spacing={6} width="100%">
          <Text fontSize="32px" fontWeight="bold" textAlign="center">
            Welcome back! Glad to see you, again!
          </Text>
          <FormControl id="phoneNumber">
            <Flex>
              <Select
                value={countryCode}
                onChange={(e) => setCountryCode(e.target.value)}
                width="31%"
                bg="#F7F8F9"
              >
                {countryOptions.map(option => (
                  <option key={option.code} value={option.code}>
                    {option.code}
                  </option>
                ))}
              </Select>
              <Input
                type="tel"
                placeholder="Phone Number"
                value={phoneNumber}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPhoneNumber(e.target.value)}
                bg="#F7F8F9"
              />
            </Flex>
          </FormControl>
        </VStack>

        {/* Bottom section */}
        <VStack spacing={4} width="100%">
          <Button 
            bg="black" 
            color="white" 
            width="full" 
            onClick={handleSendCode} 
            isLoading={isLoading}
          >
            Send Code
          </Button>
          <Text>
            Don't have an account? <Link to="/new-signup" style={{ color: 'green' }}>Register Now</Link>
          </Text>
        </VStack>
      </VStack>
    </Box>
  );
};

export default ReturningUser;