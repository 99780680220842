import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Text,
  Link,
  VStack,
  Flex,
  Box,
  Icon,
} from '@chakra-ui/react';
import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa';
import { updateCollabUserRedemptionConfirmation } from '../../../services/firebaseService';

interface CompletedCollabModalProps {
  isOpen: boolean;
  onClose: () => void;
  collabId: string;
  userConfirmRedemption: string;
}

const CompletedCollabModal: React.FC<CompletedCollabModalProps> = ({ isOpen, onClose, collabId, userConfirmRedemption }) => {

  const handleSelection = async (selection: 'yes' | 'no') => {
    try {
      await updateCollabUserRedemptionConfirmation(collabId, selection === 'yes');
      console.log(`User ${selection === 'yes' ? 'used' : 'did not use'} the offer`);
    } catch (error) {
      console.error('Error updating user confirmation:', error);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size="lg">
      <ModalOverlay />
      <ModalContent
        bg="gray.50"
        borderRadius="xl"
        boxShadow="xl"
        p={6}
      >
        <ModalHeader textAlign="center" fontSize="2xl" fontWeight="bold" color="green.500">
          Collaboration Completed!
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing={6} align="stretch">
            <Flex justify="center">
              <Icon as={FaCheckCircle} w={16} h={16} color="green.500" />
            </Flex>
            <Text textAlign="center" fontSize="lg">
              Great job! Did you enjoy your experience?
            </Text>
            <Flex justify="center" gap={8}>
              <Box
                as="button"
                bg={userConfirmRedemption === 'yes' ? "green" : "black"}
                color="white"
                px={6}
                py={3}
                borderRadius="md"
                onClick={() => handleSelection('yes')}
                _hover={{ opacity: 0.8 }}
                transition="all 0.2s"
              >
                <Flex align="center" gap={2}>
                  <Icon as={FaCheckCircle} />
                  <Text>Yes</Text>
                </Flex>
              </Box>
              <Box
                as="button"
                bg={userConfirmRedemption === 'no' ? "green" : "black"}
                color="white"
                px={6}
                py={3}
                borderRadius="md"
                onClick={() => handleSelection('no')}
                _hover={{ opacity: 0.8 }}
                transition="all 0.2s"
              >
                <Flex align="center" gap={2}>
                  <Icon as={FaTimesCircle} />
                  <Text>No</Text>
                </Flex>
              </Box>
            </Flex>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default CompletedCollabModal;