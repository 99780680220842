import React, { createContext, useState, useEffect, useContext, ReactNode } from "react";
import { collection, onSnapshot, query, where, QuerySnapshot } from "firebase/firestore";
import { firestore } from "../firebase";
import { InfluencerDataContext } from "./InfluencerDataContext";
import { Collab, Influencer } from "../types";

export const CollabDataContext = createContext<Collab[]>([]);

interface CollabDataProviderProps {
    children: ReactNode;
}

export const CollabDataProvider: React.FC<CollabDataProviderProps> = ({ children }) => {
    const { influencerData } = useContext(InfluencerDataContext) as { influencerData: Influencer | null };
    const [collabData, setCollabData] = useState<Collab[]>([]);
    
    useEffect(() => {
        if (!influencerData?.instagramId) return;

        const collabsRef = collection(firestore, "collabs");
        const q = query(collabsRef, where("influencerID", "==", influencerData.instagramId));
        
        const unsubscribe = onSnapshot(q, (querySnapshot: QuerySnapshot) => {
            const collabs: Collab[] = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() } as Collab));
            setCollabData(collabs);
        });
        
        return () => unsubscribe();
    }, [influencerData]);

    return (
        <CollabDataContext.Provider value={collabData}>
            {children}
        </CollabDataContext.Provider>
    );
};