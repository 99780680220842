import React, { useState, useEffect } from 'react';
import { Box, VStack, Heading, Text, Button, useToast, IconButton, Flex, Select } from '@chakra-ui/react';
import { useLocation, useNavigate } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import '../styles/datepicker-custom.css';
import { completeCollab, updateCollabDate } from '../../../services/firebaseService';
import { ArrowBackIcon } from '@chakra-ui/icons';
import { Campaign, Collab, Influencer } from '../../../types';
import { scheduleCollabReminder } from 'services/flaskService';

interface SelectedLocation {
  docId: string;
  fullAddress: string;
}

const ScheduleCollabPage: React.FC = () => {
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [selectedTime, setSelectedTime] = useState<string>('');
  const [selectedLocation, setSelectedLocation] = useState<SelectedLocation | null>(null);
  const [initialDate, setInitialDate] = useState<Date | null>(null);
  const [initialTime, setInitialTime] = useState<string>('');
  const [initialLocation, setInitialLocation] = useState<SelectedLocation | null>(null);
  
  const navigate = useNavigate();
  const toast = useToast();
  const location = useLocation();

  const { collab, campaign, influencer }: { collab: Collab, campaign: Campaign, influencer: Influencer } = location.state || {};

  const isDateAlreadyScheduled = collab?.scheduledCollabDate !== undefined;

  const convertFirebaseTimestampToDate = (timestamp: { seconds: number; nanoseconds: number }): Date => {
    return new Date(timestamp.seconds * 1000);
  };

  const hasCollabDatePassed = () => {
    if (!collab?.scheduledCollabDate) return false;
    const collabDate = convertFirebaseTimestampToDate(collab.scheduledCollabDate);
    return new Date() > collabDate;
  };

  const handleAlreadyRedeemed = async () => {
    try {
      await completeCollab(collab.id, collab.deliverableCounts, true);
      navigate(`/collabs/complete-collab?id=${collab.id}`, { replace: true });
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Failed to mark collab as redeemed. Please try again.',
        status: 'error',
        duration: 3000,
        isClosable: true
      });
    }
  };

  const getTimezone = (locationId?: string): string => {
    let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    if (locationId && campaign?.locations) {
      const location = campaign.locations.find(loc => loc.docId === locationId);
      if (location?.timezone) {
        timezone = location.timezone;
      }
    }
    return timezone;
  };

  useEffect(() => {
    if (collab?.scheduledCollabDate) {
      const date = convertFirebaseTimestampToDate(collab.scheduledCollabDate);
      
      // Use new getTimezone method
      const timezone = getTimezone(collab?.selectedLocation);

      // Convert the date to the location's timezone
      const options = { timeZone: timezone };
      const locationTime = new Date(date.toLocaleString('en-US', options));
      
      // Format the time string
      const timeString = locationTime.toLocaleTimeString('en-US', { 
        hour: '2-digit', 
        minute: '2-digit', 
        hour12: true,
      });

      // Set the date accounting for timezone differences
      const tzOffset = locationTime.getTime() - date.getTime();
      const adjustedDate = new Date(date.getTime() + tzOffset);

      setSelectedDate(adjustedDate);
      setSelectedTime(timeString);
      setInitialDate(adjustedDate);
      setInitialTime(timeString);
    }

    if (collab?.selectedLocation && campaign?.locations) {
      const location = campaign.locations.find(loc => loc.docId === collab.selectedLocation);
      if (location) {
        setSelectedLocation({
          docId: location.docId,
          fullAddress: location.fullAddress
        });
        setInitialLocation({
          docId: location.docId,
          fullAddress: location.fullAddress
        });
      }
    }
  }, [collab, campaign]);
  
  const isLocationValid = () => {
    if (!campaign?.locations || campaign.locations.length === 0) return true;
    return !!selectedLocation?.docId;
  };

  const isDateTimeValid = () => {
    return !!selectedDate && !!selectedTime;
  };
  const hasLocationChanges = () => {
    if (!campaign?.locations || campaign.locations.length === 0) return false;
    if (!initialLocation) return !!selectedLocation;
    return selectedLocation?.docId !== initialLocation?.docId;
  };

  const hasDateTimeChanges = () => {
    if (!initialDate || !initialTime) return true;
    return (
      selectedDate?.getTime() !== initialDate?.getTime() ||
      selectedTime !== initialTime
    );
  };

  const hasAnyChanges = () => {
    return hasLocationChanges() || hasDateTimeChanges();
  };

  const handleLocationChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedLocationId = e.target.value;
    const location = campaign?.locations?.find(loc => loc.docId === selectedLocationId);
    if (location) {
      setSelectedLocation({
        docId: location.docId,
        fullAddress: location.fullAddress
      });
    }
  };

  const handleSubmit = async () => {
    if (!collab.id || !selectedDate || !selectedTime) return;
    if (campaign?.locations?.length > 0 && !selectedLocation) return;

    const [time, modifier] = selectedTime.split(' ');
    let [hours, minutes] = time.split(':').map(Number);
    if (modifier === 'PM' && hours < 12) {
      hours += 12;
    } else if (modifier === 'AM' && hours === 12) {
      hours = 0;
    }

    // Use new getTimezone method
    const timezone = getTimezone(selectedLocation?.docId);
    
    const dateTime = new Date(
      selectedDate.getFullYear(),
      selectedDate.getMonth(),
      selectedDate.getDate(),
      hours,
      minutes
    );
    
    const localTime = new Date(dateTime);

    const options = { timeZone: timezone };
    const tzOffset = new Date(localTime.toLocaleString('en-US', options)).getTime() - localTime.getTime();
    
    localTime.setTime(localTime.getTime() - tzOffset);

    try {
      await updateCollabDate(collab.id, localTime, selectedLocation?.docId || null);
      
      toast({
        title: 'Date and time selected',
        description: 'Your collab date and time have been set successfully.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });

      // Navigate immediately after updating the date
      navigate('/collabs');

      // Schedule the reminder asynchronously
      const newDate = dateTime.toLocaleString('en-US', { 
        year: 'numeric', 
        month: '2-digit', 
        day: '2-digit',
        hour: '2-digit', 
        minute: '2-digit',
        hour12: false
      });

      let address: string;
      let locationEmails: string[] = [];
      if (selectedLocation) {
        const location = campaign?.locations?.find(loc => loc.docId === selectedLocation.docId);
        address = `${location?.streetNumber} ${location?.streetName}, ${location?.city}, ${location?.state}`;
        locationEmails = location?.emails || [];
      }

      scheduleCollabReminder(
        collab.id, 
        newDate, 
        isDateAlreadyScheduled, 
        campaign.clientName, 
        influencer.instagramHandle, 
        influencer.phoneNumber, 
        campaign.clientEmail, 
        timezone, 
        address, 
        locationEmails
      )
        .then(result => {
          if (result) {
            console.log('Collab reminder scheduled successfully');
          } else {
            console.log('Failed to schedule collab reminder');
          }
        })
        .catch(error => {
          console.error('Error scheduling collab reminder:', error);
        });

    } catch (error) {
      console.error('Error setting collab date and time:', error);
      toast({
        title: 'Error',
        description: 'Failed to set collab date and time. Please try again.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleCancel = () => {
    navigate('/collabs');
  };

  const generateTimeOptions = () => {
    if (!selectedDate) return [];

    const dayOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'][selectedDate.getDay()];
    const availableHours = campaign?.openHours?.[dayOfWeek] || ['9:00 AM', '10:00 AM', '11:00 AM', '12:00 PM', '01:00 PM', '02:00 PM', '03:00 PM', '04:00 PM', '05:00 PM', '06:00 PM', '07:00 PM', '08:00 PM', '09:00 PM', '10:00 PM'];
    
    // Use new getTimezone method
    const timezone = getTimezone(selectedLocation?.docId);

    // Get timezone abbreviation
    const timeZoneAbbr = new Date().toLocaleTimeString('en-US', {
      timeZone: timezone,
      timeZoneName: 'short'
    }).split(' ').pop();

    return availableHours.sort((a, b) => {
      const [aTime, aModifier] = a.split(' ');
      const [bTime, bModifier] = b.split(' ');

      const [aHours, aMinutes] = aTime.split(':').map(Number);
      const [bHours, bMinutes] = bTime.split(':').map(Number);

      const aEpoch = (aModifier === 'PM' && aHours < 12 ? aHours + 12 : aHours) * 60 + aMinutes;
      const bEpoch = (bModifier === 'PM' && bHours < 12 ? bHours + 12 : bHours) * 60 + bMinutes;

      return aEpoch - bEpoch;
    }).map(time => ({
      time,
      timezone: timeZoneAbbr
    }));
  };

  const isDateDisabled = (date: Date) => {
    const dayOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'][date.getDay()];
    const currentDate = new Date();
    const availableHours = campaign?.openHours?.[dayOfWeek] || ['9:00 AM', '10:00 AM', '11:00 AM', '12:00 PM', '1:00 PM', '2:00 PM', '3:00 PM', '4:00 PM', '5:00 PM', '6:00 PM', '7:00 PM', '8:00 PM', '9:00 PM', '10:00 PM'];
    const hasAvailableTimes = availableHours.some(time => {
      const [timeString, modifier] = time.split(' ');
      let [hours, minutes] = timeString.split(':').map(Number);

      if (modifier === 'PM' && hours < 12) {
        hours += 12;
      } else if (modifier === 'AM' && hours === 12) {
        hours = 0;
      }

      const selectedDateTime = new Date(date);
      selectedDateTime.setHours(hours, minutes);
      return selectedDateTime > currentDate;
    });
    return date < currentDate || !hasAvailableTimes;
  };

  const getStartDate = () => {
    return campaign?.startDate ? convertFirebaseTimestampToDate(campaign.startDate) : new Date();
  };

  const getEndDate = () => {
    return campaign?.endDate ? convertFirebaseTimestampToDate(campaign.endDate) : null;
  };

  const renderLocationSelect = () => {
    if (!campaign?.locations || campaign.locations.length === 0) {
      return null;
    }

    return (
      <Box>
        <Select
          placeholder="Select location"
          value={selectedLocation?.docId || ''}
          onChange={handleLocationChange}
          isRequired={campaign.locations.length > 0}
        >
          {campaign.locations.map((location) => (
            <option key={location.docId} value={location.docId}>
              {`${location.streetNumber} ${location.streetName}, ${location.city}, ${location.state}`}
            </option>
          ))}
        </Select>
      </Box>
    );
  };

  return (
    <Flex direction="column" minH="100vh" bg="white" p={4} position="relative">
      <IconButton
        icon={<ArrowBackIcon />}
        aria-label="Go back"
        position="absolute"
        top={4}
        left={4}
        onClick={() => navigate('/collabs')}
        variant="ghost"
      />
      <VStack spacing={6} align="stretch" flex={1}>
        <Box mt={12}>
          <Heading textAlign="center">Schedule Your Collab</Heading>
          {(campaign?.locations && campaign.locations.length > 0) && (
            <Text textAlign="center">Times are based on the timezone of the location you selected:</Text>
          )}
        </Box>
        <Box display="flex" alignItems="center" justifyContent="center" px={4}>
          <DatePicker
            selected={selectedDate}
            onChange={(date: Date) => {
              setSelectedDate(date);
              setSelectedTime('');
            }}
            minDate={getStartDate()}
            maxDate={getEndDate() }
            filterDate={date => !isDateDisabled(date)}
            inline
          />
        </Box>
        <Box>
          <Select
            placeholder="Select time"
            value={selectedTime}
            onChange={(e) => setSelectedTime(e.target.value)}
            isDisabled={!selectedDate}
          >
            {generateTimeOptions().map(({ time, timezone }) => (
              <option key={time} value={time}>
                {time} ({timezone})
              </option>
            ))}
          </Select>
        </Box>
        {renderLocationSelect()}
        <Flex direction="column" gap={2}>
          <Button
            bg="black"
            color="white"
            onClick={handleSubmit}
            isDisabled={!isDateTimeValid() || !isLocationValid() || !hasAnyChanges()}
          >
            Confirm Date and Time
          </Button>
          {isDateAlreadyScheduled && (
            <Button
              bg="gray.200"
              color="black"
              onClick={handleCancel}
            >
              Cancel
            </Button>
          )}
          {hasCollabDatePassed() && (
            <Box textAlign="center">
              <Button
                variant="ghost" 
                onClick={handleAlreadyRedeemed}
                textDecoration="underline"
              >
                Already Visited?
              </Button>
            </Box>
          )}
        </Flex>
      </VStack>
    </Flex>
  );
};

export default ScheduleCollabPage;
