import React, { useState, useContext, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { Select, Flex, Box, Button, Link as LinkComponent, Input, FormControl, FormLabel, Heading, VStack, Text, Image, Tabs, TabList, TabPanels, Tab, TabPanel, useToast, Checkbox } from '@chakra-ui/react';

const NewLoginPage: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const navigate = useNavigate();
  const toast = useToast();

  const handleSubmitNewUser = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    navigate('/new-signup');
  };

  const handleSubmitReturningUser = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    navigate('/returning-user');
  };

  return (
    <Flex
      alignItems="center"
      direction="column"
      justifyContent="space-between"
      bgGradient="linear(to-t, #939393, white 50%, white)"
      p={{ base: "2", md: "4", lg: "8" }}
      minHeight="100vh"
      width="100vw"
    >
     <Box />
        <Image 
          src="https://firebasestorage.googleapis.com/v0/b/storyitapp.appspot.com/o/touchpoints%2FFoodFluence.io%20Logo%20(461%20x%20196%20px)%20(6).png?alt=media&token=54ec9ac5-6bd9-40e1-98c4-12597c3953a1" 
          alt="FoodFluencer Logo" 
          objectFit="contain"
        />
        <Flex justify="center" width="100%" mb={125}>
          <Button bg="#2F2F2F" color="white" width="45%" mr={2} onClick={handleSubmitReturningUser}>
            Returning User
          </Button>
          <Button bg="#2F2F2F" color="white" width="45%" ml={2} onClick={handleSubmitNewUser}>
            New User
          </Button>
        </Flex>
      </Flex>
  );
}

export default NewLoginPage;