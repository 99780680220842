import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Text,
} from '@chakra-ui/react';

interface VerificationPendingModalProps {
  isOpen: boolean;
  onClose: () => void;
  clientName: string;
}

const VerificationPendingModal: React.FC<VerificationPendingModalProps> = ({ isOpen, onClose, clientName }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent mx={4}>
        <ModalHeader>Content Under Review</ModalHeader>
        <ModalBody>
          <Text>
            {clientName} is reviewing your content. We will update you with your reward once it is verified.
          </Text>
        </ModalBody>
        <ModalFooter>
          <Button bg="black" color="white" mr={3} onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default VerificationPendingModal;