import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react';
import CreativeBrief from './creativeBrief';
import { Collab, Campaign } from '../../../types';

interface CreativeBriefModalProps {
  isOpen: boolean;
  onClose: () => void;
  collab: Collab;
  campaign: Campaign | null;
}

const CreativeBriefModal: React.FC<CreativeBriefModalProps> = ({ isOpen, onClose, collab, campaign }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="full">
      <ModalOverlay />
      <ModalContent height="100vh" maxWidth="100vw" margin={0}>
        <ModalCloseButton />
        <ModalBody p={6} overflowY="auto">
          {campaign && <CreativeBrief collab={collab} campaign={campaign} />}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default CreativeBriefModal;