import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Text,
  Link,
  VStack,
} from '@chakra-ui/react';

interface PendingReviewModalProps {
  isOpen: boolean;
  onClose: () => void;
  postLink: string;
}

const PendingReviewModal: React.FC<PendingReviewModalProps> = ({ isOpen, onClose, postLink }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent mx={4}>
        <ModalHeader>Content Under Review</ModalHeader>
        <ModalBody>
          <VStack spacing={4} align="stretch">
            <Text>
              Thank you for posting! Your content is currently under review.
            </Text>
            <Text>
              Submitted link:
            </Text>
            <Link href={postLink} isExternal color="blue.500">
              {postLink}
            </Link>
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button bg="black" color="white" mr={3} onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default PendingReviewModal;