import React, { createContext, useState, ReactNode } from 'react';

interface VerificationContextProps {
  confirmationResult: any;
  setConfirmationResult: (result: any) => void;
}

export const VerificationContext = createContext<VerificationContextProps | undefined>(undefined);

export const VerificationProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [confirmationResult, setConfirmationResult] = useState<any>(null);

  return (
    <VerificationContext.Provider value={{ confirmationResult, setConfirmationResult }}>
      {children}
    </VerificationContext.Provider>
  );
};