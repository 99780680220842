import React, { useEffect, useState, useContext } from 'react';
import { Box, VStack, Text, Button, Flex, Image, IconButton, useToast } from '@chakra-ui/react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { motion } from 'framer-motion';
import { ArrowBackIcon } from '@chakra-ui/icons';
import foodfluenceIcon from '../../../assets/foodfluence-icon.png';
import { completeCollab } from '../../../services/firebaseService';
import { CollabDataContext } from '../../../contexts/collabDataContext';
import { InfluencerCampaignDataContext } from '../../../contexts/InfluencerCampaignDataContext';
import { InfluencerDataContext } from '../../../contexts/InfluencerDataContext';

const ViewRewardPage: React.FC = () => {
    const navigate = useNavigate();
    const toast = useToast();
    
    const [searchParams] = useSearchParams();
    const id = searchParams.get('id');

    const collabData = useContext(CollabDataContext);
    const campaignData = useContext(InfluencerCampaignDataContext);
    const { influencerData } = useContext(InfluencerDataContext);

    const collab = collabData.find(c => c.id === id);
    const campaign = id ? campaignData[id] : undefined;

    const [timeLeft, setTimeLeft] = useState(0);
    const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!collab || !collab.collabRedeemedTime) return;

    const calculateRemainingTime = (collab) => {
      const now = new Date();
      const redeemedAt = collab.collabRedeemedTime.toDate();
      const expirationTime = new Date(redeemedAt.getTime() + 24 * 60 * 60 * 1000); // 24 hours after redemption
      const remainingTime = expirationTime.getTime() - now.getTime();
      return Math.max(0, Math.floor(remainingTime / 1000)); // Convert to seconds
    };

    const timer = setInterval(() => {
      const remainingTime = calculateRemainingTime(collab);
      setTimeLeft(remainingTime);

      if (remainingTime <= 0) {
        clearInterval(timer);
        // navigate('/collabs');
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [collab, navigate]);

  const formatTime = (seconds: number) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  const handleCompleteReward = async () => {
    setIsLoading(true);
    try {
      if (!id) throw new Error("Collab ID is missing");
      await completeCollab(id, collab.deliverableCounts);
      toast({
        title: "Reward Completed!",
        description: "Your reward has been marked as completed.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      navigate('/collabs');
    } catch (error) {
      console.error('Error completing reward:', error);
      toast({
        title: "Error",
        description: "There was an error completing your reward. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleBack = () => {
    navigate("/collabs");
  };

  if (!collab || !campaign) {
    return <Box>Loading...</Box>;
  }

  return (
    <Box minHeight="100vh" display="flex" flexDirection="column">
      <Box flex="1" bg="black" p={6} display="flex" flexDirection="column" justifyContent="center" alignItems="center" position="relative">
        <IconButton
          icon={<ArrowBackIcon boxSize="24px" />}
          aria-label="Go back"
          position="absolute"
          top={4}
          left={4}
          onClick={handleBack}
          bg="transparent"
          color="white"
          _hover={{ bg: "rgba(255,255,255,0.1)" }}
          size="lg"
        />
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <VStack spacing={8} align="center" width="100%" maxWidth="450px">
            <Text fontSize="3xl" fontWeight="bold" color="white">
              {formatTime(timeLeft)}
            </Text>
            <Box 
              width="100%" 
              height="200px" 
              borderRadius="lg" 
              p={4}
              position="relative"
              boxShadow="0px 0px 20px rgba(255,255,255,0.1)"
              background="linear-gradient(135deg, #4a4a4a 0%, #2a2a2a 50%, #1a1a1a 100%)"
              overflow="hidden"
            >
              <Box
                position="absolute"
                top="0"
                left="0"
                right="0"
                bottom="0"
                background="linear-gradient(135deg, rgba(255,255,255,0.1) 0%, rgba(255,255,255,0.05) 50%, transparent 100%)"
              />
              <Flex justifyContent="space-between" alignItems="center" position="relative" zIndex="1">
                <Text fontSize="xl" color="white">${collab.credit} Credit</Text>
                <Image src={foodfluenceIcon} alt="Foodfluence Icon" width="60px" height="60px" />
              </Flex>
              <Flex 
                position="absolute" 
                bottom={6} 
                left={6} 
                right={6}
                flexDirection="column"
                zIndex="1"
              >
                <Text fontSize="xl" color="white">{campaign.clientName}</Text>
                <Text fontSize="md" color="gray.400">{influencerData?.instagramHandle}</Text>
              </Flex>
            </Box>
            <Text fontSize="xl" color="white" fontWeight="bold" textAlign="center">
              Show this screen to the staff at {campaign.clientName} to redeem your reward
            </Text>
          </VStack>
        </motion.div>
      </Box>
      <Box flex="1" bg="white" p={6} display="flex" flexDirection="column" justifyContent="space-between" alignItems="center">
        <Text fontSize="2xl" fontWeight="bold" color="black" >
          ${collab.credit} Credit
        </Text>
        <Text fontSize="xl" color="gray.600">
          Only Redeemable once
        </Text>
        <Button
            bg="gray.300"
            color="black"
            size="lg"
            width="80%"
            borderRadius="full"
            fontWeight="bold"
            _hover={{ bg: "gray.400" }}
            _active={{ bg: "gray.500" }}
            boxShadow="0px 4px 10px rgba(0, 0, 0, 0.1)"
            mb={8}
            onClick={handleCompleteReward}
            isLoading={isLoading}
            loadingText="Completing..."
            >
            Complete Reward
            </Button>
      </Box>
    </Box>
  );
};

export default ViewRewardPage;