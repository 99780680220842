import React, { useState, useEffect, useContext } from 'react';
import { Box, VStack, Text, Button, Flex, Image, useToast } from '@chakra-ui/react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { motion } from 'framer-motion';
import { completeCollab, redeemCollab } from '../../../services/firebaseService';
import { CollabDataContext } from '../../../contexts/collabDataContext';
import { InfluencerCampaignDataContext } from '../../../contexts/InfluencerCampaignDataContext';
import { InfluencerDataContext } from '../../../contexts/InfluencerDataContext';
import { FaExclamationTriangle } from 'react-icons/fa';

const RedeemRewardPage: React.FC = () => {
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const id = searchParams.get('id');

  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const [randomImage, setRandomImage] = useState<string | null>(null);

  const collabData = useContext(CollabDataContext);
  const campaignData = useContext(InfluencerCampaignDataContext);
  const { influencerData } = useContext(InfluencerDataContext);

  const collab = collabData.find(c => c.id === id);
  const campaign = id ? campaignData[id] : undefined;

  function importAll(r) {
    return r.keys().map(r);
  }
  
  const images = importAll((require as any).context('../../../assets/default_collab_images', false, /\.(png|jpe?g|svg)$/));
  
  const getRandomImage = () => {
    if (!randomImage) {
      const randomIndex = Math.floor(Math.random() * images.length);
      setRandomImage(images[randomIndex]);
      return images[randomIndex];
    }
    return randomImage;
  };

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'visible';
    };
  }, []);

  const handleConfirmRedemption = async () => {
    if (!id) return;
    setIsLoading(true);
    try {
      if (collab.credit > 0) {
        await redeemCollab(id);
        navigate(`/collabs/view-reward?id=${id}`);
      } else {
        await completeCollab(id, collab.deliverableCounts, true);
        navigate(-1);
      }
      toast({
        title: "Reward Redeemed!",
        description: `You've successfully redeemed. Enjoy!`,
        status: "success",
        duration: 5000,
        isClosable: true,
      });
     
    } catch (error) {
      console.error('Error redeeming collab:', error);
      toast({
        title: "Error",
        description: "There was an error redeeming your reward. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleRedeemLater = () => {
    navigate('/collabs');
  };

  if (!collab || !campaign) {
    return <Box>Loading...</Box>;
  }

  return (
    <Box minHeight="100vh" bg="black" display="flex" alignItems="center" justifyContent="center" overflow="hidden">
      <motion.div
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        style={{ width: '100%', display: 'flex', justifyContent: 'center', padding: '0 30px' }}
      >
        <VStack spacing={8} align="center" width="100%" maxWidth="450px">
          <Text fontSize="2xl" color="gray.300" textAlign="center">
            Ready to indulge at {campaign.clientName || 'the restaurant'}?
          </Text>
          <Box position="relative" width="100%" overflow="hidden" borderRadius="md">
            <Image 
              src={campaign?.collabImage ?? getRandomImage()} 
              alt="Collab Image" 
              objectFit="cover" 
              width="100%" 
              height="200px"
              onError={(e) => {
                if (!randomImage) {
                  e.currentTarget.src = getRandomImage();
                }
              }}
            />
            <Box 
              position="absolute" 
              top="0"
              left="0" 
              right="0"
              bottom="0"
              bg="rgba(0,0,0,0.5)"
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              px={4}
            >
              {collab.cash > 0 && (
                <Text fontSize="3xl" fontWeight="bold" color="white" mb={4} textAlign="center">
                  ${collab.cash} Cash
                </Text>
              )}
              {collab.credit > 0 && (
                <Text fontSize="3xl" fontWeight="bold" color="white" mb={4} textAlign="center">
                  ${collab.credit} Credit
                </Text>
              )}
            </Box>
          </Box>
          <Box>
          <Flex align="center" justify="center" fontSize="24px" color="white" textAlign="center" mx={10} mb={4}>
              <Text>Only continue if you are at the restaurant!</Text>
            </Flex>
            {collab.cash > 0 && (
              <Text fontSize="lg" color="white" textAlign="center" mx={10} mb={4}>
                Cash will be paid out once your content is verified
              </Text>
            )}
          </Box>
          <Flex direction="column" width="100%">
            <Button
              bg="purple.500"
              color="white"
              _hover={{ bg: "purple.600" }}
              mb={4}
              onClick={handleConfirmRedemption}
              isLoading={isLoading}
              loadingText="Confirming..."
            >
              Redeem Now
            </Button>
            <Button
              variant="outline"
              color="gray.300"
              borderColor="gray.300"
              _hover={{ bg: "gray.800" }}
              onClick={handleRedeemLater}
            >
              I'll Come Back Later
            </Button>
          </Flex>
        </VStack>
      </motion.div>
    </Box>
  );
};

export default RedeemRewardPage;